import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { mutate } from 'swr';
import {
  axios,
  deleteAuthToken,
  isRecruiter,
  setAuthTokenData,
} from '@nploy/shared';
import {
  ICandidateLoginDto,
  ILoginWithEmailInputModel,
} from '@nploy/ui-infrastructure';
import { CandidatesRoutes } from 'constants/routes';
import { CallbackFunction } from 'infrastructure/types';
import { eventNames, logAnalyticsEvent } from 'utils/analytics/event';
import { getUserFiltersValues } from 'utils/buildHelpers/buildApiRequests';
import { getErrorMessageFromResponseData } from 'utils/helpers/getErrorMessage';
import { clearLocalStorage, localStorageKeys } from 'utils/localStorage';
import { filtersValuesToSearchParams } from 'utils/ssr/filters-values-to-search-params';
import { fetchData } from 'utils/swr/helpers/fetchData';
import { useAppDispatch } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import {
  closeCreateAccountModal,
  closeLoginModal,
  openLoginModal,
  showEmailConfirmModal,
} from 'store/reducers/uiReducer';

const triggerMutates = () =>
  Promise.all([
    mutate('user', true),
    mutate('filters/general/get'),
    mutate('settings/onboarding-status'),
  ]);
// Trigger mutates and clear storage
const revalidateOnAuth = () => {
  clearLocalStorage();
  triggerMutates();
  Cookies.remove(localStorageKeys.dislikedIds);
  Cookies.remove(localStorageKeys.externalDislikedIds);
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const closeAllModals = () => {
    dispatch(closeLoginModal());
    dispatch(closeCreateAccountModal());
  };

  const updateJobPathWithQueryParams = async () => {
    const filters = await getUserFiltersValues();
    const searchParams = filtersValuesToSearchParams(filters);
    closeAllModals();
    dispatch(openFeedback({ type: 'success', message: 'signedIn' }));
    await router.replace({
      pathname: CandidatesRoutes.jobs,
      query: searchParams.toString(),
    });
  };

  const navigateToOnboardingIfUserNew = async () => {
    try {
      const { status } = await fetchData<{ status: boolean }>(
        'settings/onboarding-status',
      );
      if (status === false) {
        closeAllModals();
        dispatch(openFeedback({ type: 'success', message: 'signedIn' }));
        await router.replace({
          pathname: CandidatesRoutes.onboarding,
          query: { redirectUrl: router.asPath.split('#')[0] },
        });
      } else {
        await updateJobPathWithQueryParams();
      }
    } catch (e) {
      console.log('navigateToOnboardingIfUserNew error', { ...e });
    }
  };
  const onSignInSuccess = async (
    res: ICandidateLoginDto,
    callback: CallbackFunction,
    provider: string,
  ) => {
    if (res?.access_token) {
      if (isRecruiter(res.access_token)) {
        return dispatch(
          openFeedback({
            type: 'error',
            message: 'recruiterLoginNotSupported',
          }),
        );
      }
      // Set token to cookies and headers Authorization
      setAuthTokenData(res);

      if (callback) {
        callback();
      }

      revalidateOnAuth();

      logAnalyticsEvent(eventNames.signIn, { category: provider });
      await navigateToOnboardingIfUserNew();
    }
  };
  // Sign up
  const signUp = async ({ full_name, email, password }) => {
    try {
      await axios.post('auth/sign-up', {
        full_name,
        email,
        password,
      });

      dispatch(closeCreateAccountModal());
      dispatch(showEmailConfirmModal());

      logAnalyticsEvent(eventNames.signUp, { category: 'email' });
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign up with LinkedIn
  const signUpWithLinkedIn = async (linkedinCode: string) => {
    try {
      const res = await axios.post('auth/social/sign-up-linkedin', {
        linkedin_code: linkedinCode,
      });

      logAnalyticsEvent(eventNames.signUp, { category: 'linkedin' });

      return await onSignInSuccess(res.data, null, 'linkedin');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign up with Google
  const signUpWithGoogle = async (token: string) => {
    try {
      const { data } = await axios.post('auth/social/sign-up', {
        google_token: token,
      });

      logAnalyticsEvent(eventNames.signUp, { category: 'google' });

      // Sign up if token was sent
      if (data?.access_token) {
        return await onSignInSuccess(data, null, 'google');
      }

      // Show a success message with instructions if no token but a message
      if (data?.message) {
        dispatch(closeCreateAccountModal());
        dispatch(openLoginModal());
        dispatch(openFeedback({ type: 'success', message: data.message }));
      }
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign in
  const signIn = async (
    { email, password }: ILoginWithEmailInputModel,
    callback?: CallbackFunction,
  ) => {
    try {
      const { data } = await axios.post<ICandidateLoginDto>('auth/sign-in', {
        email,
        password,
      });

      await onSignInSuccess(data, callback, 'email');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, [
            'errors',
            'generalError',
          ]),
        }),
      );
      throw error;
    }
  };

  // Sign in LinkedIn
  const signInWithLinkedIn = async (
    linkedinCode: number,
    callback?: CallbackFunction,
  ) => {
    try {
      const { data } = await axios.post('auth/social/sign-in-linkedin', {
        linkedin_code: linkedinCode,
      });

      await onSignInSuccess(data, callback, 'linkedin');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign in with Google
  const signInWithGoogle = async (
    token: string,
    callback?: CallbackFunction,
  ) => {
    try {
      const { data } = await axios.post('auth/social/sign-in', {
        google_token: token,
      });

      await onSignInSuccess(data, callback, 'google');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign out
  const signOut = () => {
    deleteAuthToken();

    revalidateOnAuth();
  };

  const forgotPassword = async (email: string, callback: CallbackFunction) => {
    try {
      await axios.post('auth/forgot-password-candidate', { email });
      if (callback) callback();

      dispatch(openFeedback({ type: 'success', message: 'passwordReset' }));
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  const resetPassword = async (
    { email, password, password_confirmation, token },
    callback: CallbackFunction,
  ) => {
    try {
      await axios.post('auth/forgot-password-change', {
        email,
        password,
        password_confirmation,
        token,
      });

      dispatch(openFeedback({ type: 'success', message: 'passwordChanged' }));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  const resentConfirmationEmail = useCallback(async (email: string) => {
    try {
      await axios.post('auth/resend-confirm-email', {
        email,
      });

      dispatch(
        openFeedback({
          type: 'success',
          message: 'resendEmailSentSuccessfully',
        }),
      );
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
      throw error;
    }
  }, []);

  return {
    signUp,
    signUpWithLinkedIn,
    signUpWithGoogle,
    signIn,
    signInWithLinkedIn,
    signInWithGoogle,
    signOut,
    forgotPassword,
    resetPassword,
    resentConfirmationEmail,
  };
};
