import React, { FC, useCallback, useRef, useState } from 'react';
import Image from 'next/image';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { MoreMenu } from 'components/layout/Header';
import placeholder from '../../../../public/images/icons/ProfilePicPlaceholder.svg';
import { IDesktopMoreMenu } from './desktop-more-menu.interface';
import {
  DesktopMoreMenuImagePlaceholder,
  desktopMoreMenuStyles,
} from './desktop-more-menu.styles';

export const DesktopMoreMenu: FC<IDesktopMoreMenu> = ({
  image,
  className,
  containerClassName,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslate();

  const handleToogle = useCallback(() => {
    setOpen(!open);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    [],
  );

  return (
    <Box sx={containerClassName}>
      <ButtonBase
        ref={anchorRef}
        sx={{
          ...className,
          ...desktopMoreMenuStyles.imageCircleButton,
          ...(open && desktopMoreMenuStyles.buttonActive),
          ...(image && desktopMoreMenuStyles.placeholderBackground),
        }}
        onClick={handleToogle}
        id="more-menu-button"
        aria-label="more-menu"
      >
        <Box sx={desktopMoreMenuStyles.imageContainer}>
          {image ? (
            <Image
              src={image}
              alt={t('img.profilePictureAlt')}
              title={t('img.moreMenuTitle')}
              fill
            />
          ) : (
            <DesktopMoreMenuImagePlaceholder
              src={placeholder}
              alt={t('img.placeholderAlt')}
              title={t('img.moreMenuTitle')}
              width={25}
              height={25}
            />
          )}
        </Box>
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper sx={desktopMoreMenuStyles.menuContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <MoreMenu
                  open={open}
                  onListKeyDown={handleListKeyDown}
                  onClose={handleClose}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

DesktopMoreMenu.defaultProps = {
  image: '',
  className: {},
  containerClassName: {},
};
