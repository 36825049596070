import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';
import { dropdownNavLinks } from 'constants/dropdownNavLinks';
import useTranslate from 'utils/i18n/useTranslate';
import { DropdownNavLinks } from '../dropdown-nav-links';

const NavLinks = ({ inDrawer }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  const filteredNavLinks = Object.keys(dropdownNavLinks)
    .filter((key) => key !== 'popularJobCategories' && key !== 'quickLinks')
    .reduce((obj, key) => {
      obj[key] = dropdownNavLinks[key];
      return obj;
    }, {});
  return (
    <Box
      className={classnames(classes.navLinks, inDrawer && classes.inDrawer)}
      component="nav"
    >
      {Object.keys(filteredNavLinks).map((dropdownTitle) => (
        <DropdownNavLinks
          key={dropdownTitle}
          dropdownTitle={t(filteredNavLinks[dropdownTitle].title)}
          links={filteredNavLinks[dropdownTitle].links}
          inDrawer={inDrawer}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  navLinks: {
    display: 'flex',
    marginRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 490,
    justifyContent: 'space-between',
    flexGrow: 1,
    [theme.breakpoints.down(1100)]: {
      maxWidth: 450,
    },
  },
  inDrawer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

NavLinks.propTypes = {
  inDrawer: PropTypes.bool,
};

NavLinks.defaultProps = {
  inDrawer: false,
};

export default NavLinks;
