import React, { PropsWithChildren, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { detectIfMobileBrowser } from 'utils/helpers/detectBrowser';
import { getClientLanguage } from 'utils/i18n/clientLanguage';
import useTranslate from 'utils/i18n/useTranslate';
import {
  getFromSessionStorage,
  SessionStorageKeys,
} from 'utils/sessionStorage';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import {
  closeDeepLinksModal,
  showMobileStoresBanner,
} from 'store/reducers/uiReducer';
import { BuildCvModal } from 'components/BuildCV';
import { DeepLinksModal } from 'components/DeepLinksModal';
import { ConfirmEmailModal } from 'components/Login/confirm-email-modal';
import PrivacyPopup from 'components/Policy/PrivacyPolicy';
import { CreateAccountModal } from 'components/create-account';
import ForgotPasswordModal from '../../ForgotPasswordModal/ForgotPasswordModal';
import { LoginModal } from '../../Login';
import { AppLinksMeta, HeaderWrapper } from '../Header';
import { Footer } from '../footer';
import { SnackbarProvider } from '../snackbar-provider';
import { ILayoutProps } from './layout.interface';
import { layoutStyles } from './layout.styles';

export const Layout: React.FC<PropsWithChildren<ILayoutProps>> = ({
  children,
  title,
  description,
  image,
  jsonLd,
  appLinkPath,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isChangePasswordScreen = router.pathname.includes('/password-reset');
  const isHideElement = router.query?.supportMobileRedirect === 'nployapp';
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const { showDeepLinksModal } = useAppSelector(({ ui }) => ui);
  const {
    user: { isAuthed },
  } = useUser();
  const { t } = useTranslate();

  const isHideDeepLinkPopup =
    getFromSessionStorage(SessionStorageKeys.hideDeepLinkPopup) ||
    // Change password screen doesn't implement on mobile apps,
    // so we'll hide navigate to app modal
    isChangePasswordScreen;

  // Redirect if user has chosen another language
  useEffect(() => {
    const userDefinedLang = getClientLanguage();

    if (
      userDefinedLang &&
      userDefinedLang !== router.locale &&
      router.locales?.includes(userDefinedLang)
    ) {
      const currentPath = router.asPath;
      router.replace(currentPath, currentPath, { locale: userDefinedLang });
    }
  }, [router]);

  useEffect(() => {
    setIsMobile(detectIfMobileBrowser());
    if (!detectIfMobileBrowser() && !isHideElement) {
      dispatch(closeDeepLinksModal());
    }
    dispatch(showMobileStoresBanner());
  }, []);

  return (
    <Box sx={layoutStyles.pageContainer}>
      <Head>
        {/* HTML Meta Tags */}
        <title>{title || t('meta.defaultTitle')}</title>
        <meta
          name="description"
          content={description || t('meta.defaultDescription')}
        />
        <link rel="icon" type="image/png" href="/favicons/favicon.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon32x32.png"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content={title || t('meta.defaultTitle')} />
        <meta
          itemProp="description"
          content={description || t('meta.defaultDescription')}
        />
        <meta itemProp="image" content={image || '/images/metaImage.jpeg'} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title || t('meta.defaultTitle')} />
        <meta
          property="og:description"
          content={description || t('meta.defaultDescription')}
        />
        <meta property="og:image" content={image || '/images/metaImage.jpeg'} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://jobs.nploy.net/" />
        <meta property="twitter:site" content="@nPloy_App" />
        <meta
          property="twitter:title"
          content={title || t('meta.defaultTitle')}
        />
        <meta
          property="twitter:description"
          content={description || t('meta.defaultDescription')}
        />
        <meta
          property="twitter:image"
          content={image || '/images/metaImage.jpeg'}
        />

        {!!jsonLd && <script type="application/ld+json">{jsonLd}</script>}
      </Head>

      {appLinkPath && <AppLinksMeta appLinkPath={appLinkPath} />}

      {!isHideElement && <HeaderWrapper />}

      <Box component="main" sx={layoutStyles.main}>
        {children}
      </Box>

      <Footer />

      {/**/}
      {/* Modals */}
      {isAuthed ? <BuildCvModal /> : <CreateAccountModal />}
      <LoginModal />
      <ConfirmEmailModal />
      <ForgotPasswordModal />
      {isMobile && !isHideElement && !isHideDeepLinkPopup && <DeepLinksModal />}
      {isAuthed && !showDeepLinksModal && <PrivacyPopup />}
      {/* Snackbar */}
      <SnackbarProvider />
    </Box>
  );
};

Layout.defaultProps = {
  title: '',
  description: '',
  image: '',
  jsonLd: '',
  appLinkPath: '',
};

export default Layout;
