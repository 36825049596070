import React, { FC, forwardRef } from 'react';
import { useRouter } from 'next/router';
import { MenuList } from '@mui/material';
import { setClientLanguage } from 'utils/i18n/clientLanguage';
import i18nConfig from '../../../../i18n.json';
import { LanguageMenuItem } from '../language-menu-item';
import { ILanguageMenu } from './language-menu.interface';

export const LanguageMenu: FC<ILanguageMenu> = forwardRef<
  HTMLDivElement,
  ILanguageMenu
>(({ open, onListKeyDown, onClose }, ref) => {
  const router = useRouter();

  const { locales } = i18nConfig;

  const handleSelectLanguage = (selectedLang: string) => {
    if (selectedLang === router.locale) return;

    setClientLanguage(selectedLang);

    const currentPath = router.asPath;

    router.replace(currentPath, currentPath, { locale: selectedLang });
    onClose();
  };

  return (
    <MenuList
      ref={ref}
      autoFocus={open}
      onKeyDown={onListKeyDown}
      id="language-menu"
      aria-labelledby="language-menu-button"
      component="nav"
    >
      {locales.map((language) => (
        <LanguageMenuItem
          key={language}
          lang={language}
          onClick={handleSelectLanguage}
        />
      ))}
    </MenuList>
  );
});

// Needed to forward refs for click away listener
LanguageMenu.displayName = 'LanguageMenu';

LanguageMenu.defaultProps = {
  onListKeyDown: () => {
    // do nothing
  },
  open: false,
};
